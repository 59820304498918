
.card-coupon.v-card--disabled {
    pointer-events: auto;
    user-select: auto;
    cursor: pointer;
    opacity: 0.3;
    & > :not(.v-card__progress) {
        opacity: 1;
    }
    &.theme--light {
        &.v-card {
            color: var(--default-color);
        }
        &.v-sheet--outlined {
            border: 1px solid var(--border-color);
        }
    }
}
