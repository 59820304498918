
.fixed-contents {
    &__fixed-con {
        display: none;
        height: 100%;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .fixed-section {
        position: relative;
        display: block;
        &.active {
            .fixed-contents {
                &__fixed {
                    position: fixed;
                    top: 112px;
                    & + .fixed-contents__fixed-con {
                        display: block;
                    }
                }
            }
        }
        &.bottom {
            .fixed-contents {
                &__fixed {
                    position: absolute !important;
                    top: auto !important;
                    left: auto !important;
                }
                &__fixed-con {
                    display: block;
                }
            }
        }
    }
    .fixed-contents {
        position: relative;
        &__fixed,
        &__fixed-con {
            width: 290px;
        }
    }
}
