
::v-deep {
    .product-category-list {
        padding: 0;
        color: var(--v-grey-darken4) !important;
        .v-list-item {
            display: flex;
            align-items: center;
            min-height: 26px;
            color: var(--v-grey-base) !important;
            &,
            &__title {
                font-size: 14px;
            }
            &__title {
                order: 0;
            }
            &,
            &__content {
                padding: 0 !important;
            }

            // item icon
            .v-icon {
                font-size: 16px;
                color: inherit;
                text-decoration: none;
            }
        }

        // group
        .v-list-group {
            // group icon
            &__header__prepend-icon {
                min-width: auto;
                .mdi-menu-down::before {
                    content: "\F0140";
                }
            }
            &__header__append-icon {
                min-width: auto;
            }

            // group items
            &__items {
                // padding-left: 6px !important;
                .v-list-item {
                    &:not(.v-list-item--active):not(.v-list-item--disabled) {
                        color: var(--v-grey-base) !important;
                    }
                    &--exact-active {
                        > .v-list-item__content,
                        .v-list-item__title {
                            text-decoration: underline;
                            color: var(--v-grey-darken4) !important;
                        }
                        .v-icon {
                            text-decoration: none !important;
                        }
                    }
                }
            }

            // sub-group
            .v-list-group--sub-group {
                .v-list-item__icon:first-child {
                    order: 1;
                    margin: 0;
                    margin-top: 4px;
                }
            }
        }

        // 첫번째 item
        > .v-list-item,
        > .v-list-group > .v-list-item {
            min-height: 32px;
            font-weight: 700;
            .v-list-item__title {
                font-size: 14px;
            }
            color: var(--v-grey-darken4) !important;
            &--exact-active {
                color: var(--v-primary-base) !important;
            }
            &:not(.product-category-list--active) {
                color: var(--v-grey-darken4) !important;
            }
            &,
            .product-category-list {
                &--active {
                    color: var(--v-primary-base) !important;
                    > .v-list-item__content,
                    .v-list-item__title {
                        text-decoration: none !important;
                    }
                }
            }
        }
        > *:nth-child(2) {
            .product-category-list--active {
                color: var(--v-red-base) !important;
            }
        }
        > .v-list-group {
            > .v-list-group__items {
                padding-left: 6px;
                margin-top: -2px;
                margin-bottom: 2px;
                // 두번째 item
                > .v-list-item,
                > .v-list-group > .v-list-item {
                    &--exact-active,
                    &.product-category-list--active {
                        > .v-list-item__content,
                        .v-list-item__title {
                            font-weight: 500;
                        }
                    }
                }
            }
        }

        .product-category-list {
            &--active {
                > .v-list-item__content,
                .v-list-item__title {
                    text-decoration: underline;
                }
                .v-icon {
                    text-decoration: none !important;
                }
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    ::v-deep {
        .product-category-list {
            .v-list-item {
                .v-icon {
                    font-size: 18px;
                }
            }
            > .v-list-item,
            > .v-list-group > .v-list-item {
                .v-list-item__title {
                    font-size: 16px;
                }
            }
        }
    }
}
@media (min-width: 1024px) {
    ::v-deep {
        .product-category-list {
            .v-list-item {
                min-height: 30px;
            }
            .v-list-group {
                .v-list-group--sub-group {
                    .v-list-item__icon:first-child {
                        margin-top: 4px;
                    }
                }
            }
            > .v-list-item,
            > .v-list-group > .v-list-item {
                min-height: 40px;
            }
            > .v-list-group {
                > .v-list-group__items {
                    margin-top: -2px;
                    margin-bottom: 5px;
                }
            }
        }
    }
}
@media (min-width: 1200px) {
}
